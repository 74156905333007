import { useState } from "react";
import ValidationErrorList from "./ValidationErrorList";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

export default function CreateUploadSchedule({
  centre,
  getExportList,
  setNewModalContent,
  setShowNewModal,
}) {
  const [exportClassList, setExportClassList] = useState([]);
  const [exportFilename, setExportFilename] = useState();
  const isVirtual = centre?.is_virtual;

  function validatePublishSchedule(classList) {
    let incompleteClassCount = 0;
    let pastClassCount = 0;
    const validationList = [
      "centre",
      "lesson",
      "dateStr",
      "duration",
      "teacher",
      "classroom",
      "capacity",
      "delivery",
      "credit",
      "levels",
      "products",
    ];
    const virtualValidationList = [
      "centres",
      "lesson",
      "dateStr",
      "duration",
      "teacher",
      "capacity",
      "delivery",
      "credit",
      "levels",
      "products",
    ];
    for (const classInstance of classList) {
      let emptyCellCount = 0;
      for (const item of isVirtual ? virtualValidationList : validationList) {
        classInstance[item] === "" && emptyCellCount++;
      }
      emptyCellCount > 0 && incompleteClassCount++;
    }
    for (const classInstance of classList) {
      classInstance["dateStr"] < dayjs(new Date()).format() && pastClassCount++;
    }
    return { a: incompleteClassCount, b: pastClassCount };
  }

  function getExportClassList(e) {
    let classList = getExportList();
    let errors = validatePublishSchedule(classList);
    // console.log(errors);
    if (errors.a === 0 && errors.b === 0) {
      setExportFilename(
        centre?.centre_code +
          "_schedule_" +
          dayjs(new Date()).format("YYYYMMDDHHmmss")
      );
      let exportArray = [];
      if (classList.length > 0) {
        for (const item of classList) {
          let newItem = {};
          newItem["class_date_time"] = dayjs(item.dateStr).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          newItem["duration"] = Number(item.duration?.value);
          newItem["teacher_id"] = Number(item.teacher?.value);
          item.lesson
            ? (newItem[item.lesson.type + "_id"] = item.lesson?.value)
            : (newItem["lesson_id"] = null);
          newItem["lesson_time_type"] = Number(item.centre?.is_virtual + 1);
          newItem["classroom"] = isVirtual ? "" : item.classroom?.value;
          newItem["credit"] = Number(item.credit?.value);
          newItem["capacity"] = Number(item.capacity?.value);
          newItem["level"] =
            item.levels.length > 0 &&
            item.levels.map((level) => {
              return Number(level.value);
            });
          newItem["membership_type"] =
            item.products.length > 0 &&
            item.products.map((product) => {
              return product.value;
            });
          newItem["lesson_time_centre"] =
            isVirtual && item.virtualCentres.length > 0
              ? item.virtualCentres.map((virtualCentre) => {
                  return virtualCentre.value;
                })
              : item.centre?.value;
          newItem["class_type"] = Number(item.isClosed?.value);
          exportArray.push(newItem);
        }
        setExportClassList(exportArray);
      }
    } else {
      setNewModalContent([
        <ValidationErrorList
          errors={errors}
          setShowNewModal={setShowNewModal}
        />,
        false,
      ]);
      setShowNewModal(true);
      return false;
    }
  }

  return (
    <CSVLink
      type="button"
      data={exportClassList}
      filename={exportFilename}
      className="schedule-form-button csv-button"
      onClick={getExportClassList}
    >
      Export
    </CSVLink>
  );
}
