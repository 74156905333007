import { useEffect, useState } from "react";
import Select from "react-select";
import NewModalCloseButton from "./NewModalCloseButton";
import Password from "../../account/Password.js";
import { useUserSettings } from "../../auth/useUserSettings";
import { createTimeSlots } from "./scripts/createTimeSlots";
import dayjs from "dayjs";
import fetchData from "./scripts/fetchData.js";

export default function ScheduleSettings({ setShowNewModal }) {
  const [selectedTab, setSelectedTab] = useState("settings");
  const [userSettings, setUserSettings] = useUserSettings();
  const [countries, setCountries] = useState([]);
  const [centre, setCentre] = useState();
  const [countryOptions, setCountryOptions] = useState([]);
  const [centreOptions, setCentreOptions] = useState([]);
  const [historySinceOptions, setHistorySinceOptions] = useState([]);
  const [timezone, setTimezone] = useState();

  function getTimezone() {
    let guessTimezone = dayjs.tz.guess();
    const date = new Date();
    const offset = (date.getTimezoneOffset() * -1) / 60;
    let timezoneStr = guessTimezone + " (GMT+" + offset + ")";
    setTimezone(timezoneStr);
  }

  function getUserDefault() {
    if (countryOptions.length > 0) {
      let defaultCountry =
        countryOptions[
          countryOptions
            .map((object) => object.value)
            .indexOf(userSettings?.user_settings.default_country)
        ];
      updateCentres(defaultCountry);
    }
  }

  const getCountries = async () => {
    const countryAccessList = userSettings.country_access;
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/country-list`;
    const response = await fetchData(url, "GET");
    const accessList = response.filter((country) => {
      return countryAccessList.includes(country.country_id);
    });
    setCountries(accessList);
    let countryList = accessList.map((option) => {
      return {
        value: option.country_id,
        label: option.country_name,
      };
    });
    setCountryOptions(countryList);
  };

  const updateCentres = async (country) => {
    setCentre("");
    const country_id = country?.value;
    let centreList = countries?.find((item) => {
      return item.country_id === country_id;
    });
    setCentreOptions(
      centreList.centres?.map((option) => {
        return {
          value: option.centre_id,
          label: option.centre_name + " (" + option.centre_code + ")",
        };
      })
    );
  };

  useEffect(() => {
    getCountries();
    getTimezone();
    getHistorySinceOptions(2020);
  }, []);
  useEffect(() => {
    getUserDefault();
  }, [countryOptions]);

  const updateUserSettings = async (data, formElement) => {
    let adminId = userSettings.admin_id;
    let newUserSettings = {
      ...userSettings.user_settings,
      [formElement.name]: Number(data.value),
    };
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/admin/${adminId}`;
    const response = await fetchData(url, "PUT", newUserSettings);
    setUserSettings(response);
  };

  const timezoneOptions = [{ value: timezone, label: timezone }];
  const dayOptions = [
    { value: 1, label: "Monday" },
    { value: 2, label: "Tueday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
    { value: 0, label: "Sunday" },
  ];
  const teacherSearchOptions = [
    { value: 0, label: "Centre" },
    { value: 1, label: "Country" },
  ];
  const getHistorySinceOptions = (year) => {
    let options = [];
    for (let i = year; i <= dayjs(new Date()).get("year"); i++) {
      options.push({ value: i, label: i });
    }
    setHistorySinceOptions(options);
  };
  const getIntervalOptions = (range) => {
    let options = [];
    range.forEach((num) => options.push({ value: num, label: num }));
    return options;
  };
  const intervalOptions = getIntervalOptions([
    1, 2, 3, 4, 5, 10, 15, 20, 30, 45, 60,
  ]);
  const calendarDisplayOptions = getIntervalOptions([1, 2, 3, 4, 5, 6]);
  const gapOptions = getIntervalOptions([0, 1, 2, 3, 4, 5, 10, 15]);
  const timeslotOptions = createTimeSlots(
    0,
    1439,
    userSettings?.user_settings.timeslot_interval || 5
  );

  return (
    <div className="settings-modal-div">
      <h2>Settings</h2>
      <div className="modal-tab-div">
        <div
          className={` ${
            selectedTab === "settings" ? "selected-tab" : undefined
          } modal-tab tab-left`}
          onClick={() => {
            setSelectedTab("settings");
          }}
        >
          Settings
        </div>
        <div
          className={` ${
            selectedTab === "password" ? "selected-tab" : undefined
          } modal-tab tab-right`}
          onClick={() => {
            setSelectedTab("password");
          }}
        >
          Password
        </div>
      </div>

      {selectedTab === "settings" ? (
        <>
          <p>
            <b>Personal settings - {userSettings.username}</b>
          </p>
          <div className="password-compliance-text">
            You may need to log out and log in again for changes to take effect.
          </div>
          <table className="user-settings-table">
            <thead></thead>
            <tbody>
              <tr>
                <td>Default country:</td>
                <td>
                  <Select
                    name="default_country"
                    isDisabled
                    className="schedule-form-settings-selector"
                    value={
                      countryOptions[
                        countryOptions
                          .map((object) => object.value)
                          .indexOf(userSettings?.user_settings.default_country)
                      ]
                    }
                    options={countryOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "default_country" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Default centre:</td>
                <td>
                  <Select
                    name="default_country"
                    className="schedule-form-settings-selector"
                    value={
                      centreOptions[
                        centreOptions
                          .map((object) => object.value)
                          .indexOf(userSettings?.user_settings.default_centre)
                      ]
                    }
                    options={centreOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "default_centre" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Time zone:</td>
                <td>
                  <Select
                    name="timezone"
                    className="schedule-form-settings-selector"
                    isDisabled
                    value={{ value: timezone, label: timezone }}
                    // value={
                    //   timezoneOptions[
                    //     timezoneOptions
                    //       .map((object) => object.value)
                    //       .indexOf(userSettings?.user_settings.timezone)
                    //   ]
                    // }
                    options={timezoneOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "timezone" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Calendar months to display:</td>
                <td>
                  <Select
                    name="calendar_display"
                    className="schedule-form-settings-selector"
                    value={
                      calendarDisplayOptions[
                        calendarDisplayOptions
                          .map((object) => object.value)
                          .indexOf(userSettings?.user_settings.calendar_display)
                      ]
                    }
                    options={calendarDisplayOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "calendar_display" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>First day of week:</td>
                <td>
                  <Select
                    name="first_day_of_week"
                    className="schedule-form-settings-selector"
                    value={
                      dayOptions[
                        dayOptions
                          .map((object) => object.value)
                          .indexOf(
                            userSettings?.user_settings.first_day_of_week
                          )
                      ]
                    }
                    options={dayOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "first_day_of_week" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>First time slot:</td>
                <td>
                  <Select
                    name="first_timeslot"
                    className="schedule-form-settings-selector"
                    value={
                      timeslotOptions[
                        timeslotOptions
                          .map((object) => object.value)
                          .indexOf(userSettings?.user_settings.first_timeslot)
                      ]
                    }
                    options={timeslotOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "first_timeslot" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Last time slot:</td>
                <td>
                  <Select
                    name="last_timeslot"
                    className="schedule-form-settings-selector"
                    value={
                      timeslotOptions[
                        timeslotOptions
                          .map((object) => object.value)
                          .indexOf(userSettings?.user_settings.last_timeslot)
                      ]
                    }
                    options={timeslotOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "last_timeslot" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Time slot interval:</td>
                <td>
                  <Select
                    name="timeslot_interval"
                    className="schedule-form-settings-selector"
                    value={
                      intervalOptions[
                        intervalOptions
                          .map((object) => object.value)
                          .indexOf(
                            userSettings?.user_settings.timeslot_interval
                          )
                      ]
                    }
                    options={intervalOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "timeslot_interval" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Gap between classes (minutes):</td>
                <td>
                  <Select
                    name="class_gap"
                    className="schedule-form-settings-selector"
                    isDisabled
                    value={
                      gapOptions[
                        gapOptions
                          .map((object) => object.value)
                          .indexOf(userSettings?.user_settings.class_gap)
                      ]
                    }
                    options={gapOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "class_gap" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Teacher list by:</td>
                <td>
                  <Select
                    name="teacher_search"
                    className="schedule-form-settings-selector"
                    value={
                      teacherSearchOptions[
                        teacherSearchOptions
                          .map((object) => object.value)
                          .indexOf(userSettings?.user_settings.teacher_search)
                      ]
                    }
                    options={teacherSearchOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "teacher_search" })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Lesson/activity history since:</td>
                <td>
                  <Select
                    name="history_since"
                    className="schedule-form-settings-selector"
                    value={
                      historySinceOptions[
                        historySinceOptions
                          .map((object) => object.value)
                          .indexOf(userSettings?.user_settings.history_since)
                      ]
                    }
                    options={historySinceOptions}
                    onChange={(data) =>
                      updateUserSettings(data, { name: "history_since" })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <Password
          userSettings={userSettings}
          setUserSettings={setUserSettings}
        />
      )}
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </div>
  );
}
