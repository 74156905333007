import React, { useEffect, useState } from "react";
import NewModal from "./NewModal.js";
import LessonHistory from "./LessonHistory";
import SessionForm from "./SessionForm.js";
import dayjs from "dayjs";
import fetchData from "./scripts/fetchData.js";
import obsClassLink from "./scripts/obsClassLink.js";
import arrToStr from "./scripts/arrayToString.js";
import closed_icon from "./images/closed_icon.svg";
import open_icon from "./images/open_icon.svg";
import session_icon from "./images/session_icon.png";
import session_icon_off from "./images/session_icon_off.png";

export default function TeacherSchedule({
  centre,
  teacher,
  loadedSchedule,
  closeModal,
  mode,
}) {
  const [teacherName, setTeacherName] = useState("");
  const [teacherClassList, setTeacherClassList] = useState([]);
  const [showAllSchedules, setShowAllSchedules] = useState(false);
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);

  const getClasses = async (teacher) => {
    let teacherId = teacher?.value;
    let fullTeacherSchedule = [];
    if (!showAllSchedules) {
      if (loadedSchedule) {
        fullTeacherSchedule = loadedSchedule.schedule_classlist?.filter(
          (item) => item.teacher?.value === teacherId
        );
      } else {
        setShowAllSchedules(true);
      }
    } else {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/teacher-schedules/${teacherId}`;
      const response = await fetchData(url, "GET");
      if (response) {
        fullTeacherSchedule = response.reduce((acc, schedule) => {
          const tempList = schedule.schedule_classlist
            .filter((item) => item.teacher?.value === teacherId)
            .map((item) => ({
              ...item,
              schedule_id: schedule._id,
              schedule_name: schedule.schedule_name,
            }));
          return acc.concat(tempList);
        }, []);
      }
    }
    const sortedSchedule = fullTeacherSchedule.sort((a, b) =>
      a.dateStr > b.dateStr ? 1 : -1
    );
    setTeacherClassList(sortedSchedule);
  };

  useEffect(() => {
    getClasses(teacher);
    setTeacherName(teacher?.label);
  }, [teacher, showAllSchedules]);

  const showLessonHistory = async (lessonId, lessonTitle, classType) => {
    setNewModalContent([
      <LessonHistory
        centre={centre}
        lessonId={lessonId}
        lessonTitle={lessonTitle}
        classType={classType}
        setShowNewModal={setShowNewModal}
        teacherName={teacherName}
      />,
      true,
    ]);
    setShowNewModal(true);
  };

  const showSessionDetails = async (activityDetails) => {
    let activityList = [];
    activityList.push(activityDetails);
    setNewModalContent([
      <SessionForm
        activityList={activityList}
        setShowNewModal={setShowNewModal}
        mode="view"
      />,
      false,
    ]);
    setShowNewModal(true);
  };

  const teacherSchedule = teacherClassList?.map((item) => {
    return (
      <tr
        key={item.temp_id}
        className={
          loadedSchedule
            ? item.schedule_id === loadedSchedule._id
              ? "selected-schedule"
              : undefined
            : undefined
        }
      >
        <td
          title={item.schedule_name}
          className={`schedule-table-row-selector ${
            item.class_id && "published-class"
          }`}
        >
          {" "}
        </td>
        <td className="class-id-cell text-column">
          <span
            className={item.class_id ? "obs-link" : undefined}
            title={item.class_id ? "Open CMS2 class page" : "Centre"}
            onClick={() => obsClassLink(item.class_id, centre?.region)}
          >
            {item.class_id ? item.class_id : item.centre.centre_code}
          </span>
        </td>
        <td
          className={`${
            item.dateStr < dayjs(new Date()).format() && "past-date"
          } schedule-table-centred-column`}
        >
          {dayjs(item.dateStr).format("DD/MM/YYYY")}
        </td>
        <td>{dayjs(item.dateStr).format("HH:mm")}</td>
        <td>{dayjs(item.dateStr).format("ddd")}</td>
        <td className="numeric-column">{item.duration?.value}</td>
        <td className="text-column">{item.classroom?.label}</td>
        <td className="numeric-column">{item.capacity?.value}</td>
        <td className="schedule-table-centred-column schedule-table-content">
          {item.module && item.module.label}
        </td>
        <td className="schedule-table-centred-column schedule-table-content">
          {item.lesson?.type === "activity" ? (
            <span title={item.lesson.category && item.lesson.category}>
              {item.lesson.category && item.lesson.category.charAt(0)}
            </span>
          ) : (
            <span title={item.pathway && item.pathway.label}>
              {item.pathway && item.pathway.label.charAt(0)}
            </span>
          )}
        </td>
        <td className="schedule-table-content">{item.theme?.value}</td>
        <td className="text-column">{item.lesson?.label}</td>
        <td className="schedule-table-content icon-cell">
          <span
            title="Show lesson or activity history"
            className="history-icon"
            onClick={() =>
              showLessonHistory(
                item.lesson.value,
                item.lesson.label,
                item.lesson.type
              )
            }
          >
            <i className="fa-regular fa-clock-rotate-left"></i>
          </span>
        </td>
        <td className="schedule-table-content schedule-table-centred-column">
          {item.lesson && item.lesson?.type === "activity" && (
            <img
              src={item.lesson?.session_name ? session_icon : session_icon_off}
              alt="Session details icon"
              title="Session details"
              className="schedule-table-session-icon"
              onClick={() => showSessionDetails(item)}
            />
          )}
        </td>
        <td className="schedule-table-content">
          {item.levels.length > 0 && arrToStr(item.levels)}
        </td>
        <td className="schedule-table-content">
          {item.products.length > 0 && arrToStr(item.products)}
        </td>
        <td className="schedule-table-content schedule-table-centred-column">
          {item.credit.value}
        </td>
        <td className="schedule-table-centred-column schedule-table-image-cell">
          {item.isClosed.value ? (
            <img
              src={closed_icon}
              alt="Closed"
              title="Closed class"
              className="schedule-table-class-type-icon"
            />
          ) : (
            <img
              src={open_icon}
              alt="Open"
              title="Open class"
              className="schedule-table-class-type-icon"
            />
          )}
        </td>
      </tr>
    );
  });
  return (
    <div className="schedule-view-div">
      <h2>{teacherName && teacherName}</h2>
      <hr />
      <div className="schedule-container-div">
        <div className="schedule-container-left-div">
          {showAllSchedules
            ? `Showing classes from all working schedules ${
                loadedSchedule
                  ? "(Classes from current schedule highlighted)"
                  : ""
              }`
            : "Showing classes from current loaded schedule only"}
        </div>
        <div className="schedule-container-right-div">
          {loadedSchedule && (
            <label htmlFor="showAll" className="header-checkbox-label">
              <input
                type="checkbox"
                id="showAll"
                name="showAll"
                checked={showAllSchedules}
                className="filter-checkbox"
                onChange={() => setShowAllSchedules(!showAllSchedules)}
              />
              Show classes from all working schedules
            </label>
          )}
        </div>
      </div>
      <table className="schedule-table">
        <thead>
          <tr>
            <th></th>
            <th>Centre</th>
            <th>Date</th>
            <th>Time</th>
            <th>Day</th>
            <th>Dur.</th>
            <th>Room</th>
            <th>Cap.</th>
            <th>Mod.</th>
            <th>Pth.</th>
            <th>Theme</th>
            <th>Lesson</th>
            <th className="icon-cell">
              <span title="Lesson or activity history">
                <i className="fa-regular fa-clock-rotate-left header-icon"></i>
              </span>
            </th>
            <th>S</th>
            <th>Level(s)</th>
            <th>Product(s)</th>
            <th>Credit</th>
            <th>
              <i
                className="fa fa-lg fa-lock"
                aria-hidden="true"
                size="lg"
                title="Closed"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody className="lesson-history-table-body">{teacherSchedule}</tbody>
      </table>
      {!teacherClassList.length > 0 && (
        <div className="no-data-div">{`No classes ${
          showAllSchedules ? "in working schedules" : "in current schedule"
        }.`}</div>
      )}
      <div className="modal-button-div">
        <button className="schedule-form-button" onClick={closeModal}>
          Close
        </button>
      </div>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}
