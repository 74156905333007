import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoginHistory from "./LoginHistory";
import NewModal from "../components/schedule/NewModal";
import dayjs from "dayjs";
import fetchData from "../components/schedule/scripts/fetchData.js";

export default function AdminAccounts() {
  const [adminAccounts, setAdminAccounts] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [newModalContent, setNewModalContent] = useState();

  const getAdminAccounts = async () => {
    try {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/admin`;
      const response = await fetchData(url, "GET");
      setAdminAccounts(response);
    } catch (error) {
      console.error("Failed to fetch user accounts:", error);
    }
  };

  useEffect(() => {
    getAdminAccounts();
  }, []);

  function showLoginHistory(username) {
    setNewModalContent([
      <LoginHistory username={username} setShowNewModal={setShowNewModal} />,
      true,
    ]);
    setShowNewModal(true);
  }

  const adminAccountList = adminAccounts?.map((account) => {
    return (
      <tr key={account._id}>
        <td>
          <span
            className="link"
            onClick={() => showLoginHistory(account.username)}
          >
            {account._id}
          </span>
        </td>
        <td className="numeric-column">{account.admin_id}</td>
        <td>{account.username}</td>
        <td>{account.email}</td>
        <td>{account.centre_name}</td>
        <td>{account.country_name}</td>
        <td className="centered_column">
          {account.last_login &&
            dayjs(account.last_login).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="centered_column">
          {dayjs(account.updatedAt).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="centered_column">
          {dayjs(account.createdAt).format("DD/MM/YYYY HH:mm")}
        </td>
      </tr>
    );
  });

  return (
    <>
      <h2>Users</h2>
      <hr />
      <div className="schedule-container-div">
        <div className="schedule-container-left-div"></div>
        <div className="schedule-container-right-div">
          <Link to="/new-admin-account">
            {/* <button className="schedule-form-button">New account</button> */}
          </Link>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>ID</td>
            <td>OBS ID</td>
            <td>Username</td>
            <td>Email</td>
            <td>Centre</td>
            <td>Country</td>
            <td>Last login</td>
            <td>Updated</td>
            <td>Created</td>
          </tr>
        </thead>
        <tbody>{adminAccounts && adminAccountList}</tbody>
      </table>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </>
  );
}
