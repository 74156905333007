import { useEffect, useState } from "react";
import Select from "react-select";
import StrandTable from "./StrandTable.js";
import CentreSelector from "./CentreSelector.js";
import InvalidLessonWarning from "./InvalidLessonWarning.js";
import NewModal from "./NewModal";
import NewStrandForm from "./NewStrandForm";
import DeleteConfirmation from "./DeleteConfirmation.js";
import fetchData from "./scripts/fetchData.js";

export default function Strands() {
  const [centre, setCentre] = useState();
  const [localeArray, setLocaleArray] = useState([]);
  const [globalStrands, setGlobalStrands] = useState([]);
  const [localStrands, setLocalStrands] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(() =>
    JSON.parse(localStorage.getItem("selectedLevel"))
  );
  const [newStrandCount, setNewStrandCount] = useState(0);
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);

  function getLocales() {
    let locales = [];
    if (centre) {
      Object.keys(centre).forEach((key) => {
        ["centre_code", "country_code", "cluster"].includes(key) &&
          centre[key] &&
          locales.push(centre[key].toUpperCase());
      });
      locales.push("GBL");
      setLocaleArray(locales.reverse());
    } else {
      setLocaleArray(["GBL"]);
    }
  }

  async function getStrandsByLevel() {
    if (selectedLevel) {
      localStorage.setItem("selectedLevel", JSON.stringify(selectedLevel));
      const level_code = selectedLevel.value;
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/strands/${level_code}`;
      const response = await fetchData(url, "POST", localeArray);
      setGlobalStrands(
        response.filter((strand) => {
          return strand.strand_type === 0;
        })
      );
      setLocalStrands(
        response.filter((strand) => {
          return strand.strand_type > 0;
        })
      );
    }
  }

  const deleteStrand = async (strandId) => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/strands/${strandId}`;
    await fetchData(url, "DELETE");
    setNewStrandCount(newStrandCount - 1);
    setShowNewModal(false);
  };

  function confirmDeleteStrand(strandId) {
    setNewModalContent([
      <DeleteConfirmation
        deleteMessage={`Are you sure you want to delete this lesson group?`}
        deleteFunction={() => deleteStrand(strandId)}
        setShowNewModal={setShowNewModal}
      />,
      false,
    ]);
    setShowNewModal(true);
  }

  useEffect(() => {
    getStrandsByLevel();
  }, [localeArray, selectedLevel, newStrandCount]);
  useEffect(() => {
    getLocales();
  }, [centre]);

  function showNewStrandForm(strand_id) {
    setNewModalContent([
      <NewStrandForm
        newStrandCount={newStrandCount}
        setNewStrandCount={setNewStrandCount}
        setShowNewModal={setShowNewModal}
        strand_id={strand_id}
        localeArray={localeArray}
        levelOptions={levelOptions}
        showInvalidLessonWarning={showInvalidLessonWarning}
      />,
      false,
    ]);
    setShowNewModal(true);
  }

  function showInvalidLessonWarning(invalidList) {
    setNewModalContent([
      <InvalidLessonWarning
        invalidLessonList={invalidList}
        setShowNewModal={setShowNewModal}
      />,
      false,
    ]);
    setShowNewModal(true);
  }

  const getLevelOptions = async () => {
    if (centre) {
      const centreId = centre.centre_id;
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/levels/${centreId}`;
      const response = await fetchData(url, "GET");
      if (response) {
        const levelList = response.data.map((option) => {
          return {
            value: option.level_code,
            label: option.level_name,
            level_id: option.level_id,
          };
        });
        setLevelOptions(levelList);
      }
    }
  };

  useEffect(() => {
    getLevelOptions();
  }, [centre]);

  return (
    <>
      <h2>Lesson groups</h2>
      <hr />
      <div className="schedule-container-div">
        <div className="schedule-container-left-div">
          <div className="centre-selector-div">
            <CentreSelector centre={centre} setCentre={setCentre} />
            <Select
              className="strand-level-name-selector"
              value={selectedLevel}
              placeholder="Level"
              cacheOptions
              options={levelOptions}
              onChange={(data) => setSelectedLevel(data)}
            />
          </div>
        </div>
        <div className="schedule-container-right-div">
          <button
            className="schedule-form-button"
            onClick={() => showNewStrandForm(null)}
            disabled={!centre}
          >
            New lesson group
          </button>
        </div>
      </div>
      <div>
        {/* <h3>Local lesson groups</h3> */}
        <StrandTable
          strandType="1"
          strandList={localStrands}
          confirmDeleteStrand={confirmDeleteStrand}
          showNewStrandForm={showNewStrandForm}
        />
      </div>
      <div>
        <h3>Global lesson groups</h3>
        <StrandTable
          strandType="0"
          strandList={globalStrands}
          confirmDeleteStrand={confirmDeleteStrand}
          showNewStrandForm={showNewStrandForm}
        />
      </div>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={false}
      />
    </>
  );
}
