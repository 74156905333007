import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import NewModal from "./NewModal";
import Help from "./Help";

import fetchData from "./scripts/fetchData.js";
import info_icon from "./images/info_icon.png";

export default function Sessions({
  centre,
  disabledDates,
  selectedSession,
  setSelectedSession,
  closeModal,
}) {
  const today = dayjs(new Date()).format();
  const [sessionName, setSessionName] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [fromDate, setFromDate] = useState(
    dayjs(today).startOf("date").valueOf()
  );
  const [toDate, setToDate] = useState(dayjs(today).startOf("date").valueOf());
  const [sessionList, setSessionList] = useState([]);
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const centre_id = centre?.centre_id;
  const sessionNameRef = useRef();

  const session = {
    centre_id: centre_id,
    session_name: sessionName,
    from_date: dayjs(fromDate),
    to_date: dayjs(toDate),
  };

  function handleSessionChange(sessionId) {
    sessionId ? setSelectedOption(sessionId) : setSelectedOption("");
  }

  function confirmSessionChange() {
    let session = "";
    if (selectedOption) {
      session = sessionList.find((session) => {
        return session._id === selectedOption;
      });
    }
    setSelectedSession(session);
    closeModal();
  }

  const getCentreSessionList = async () => {
    if (centre) {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/sessions/${centre_id}`;
      const response = await fetchData(url, "GET");
      setSessionList(response);
    }
  };

  const addSession = async (e) => {
    e.preventDefault();
    if (!sessionName?.trim()) {
      sessionNameRef.current.focus();
    } else {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/session`;
      await fetchData(url, "POST", session);
      setSessionName("");
      getCentreSessionList();
    }
  };

  const deleteSession = async (sessionId) => {
    if (selectedOption === sessionId) {
      setSelectedOption("");
      setSelectedSession("");
    }
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/session/${sessionId}`;
    await fetchData(url, "DELETE");
    getCentreSessionList();
  };

  useEffect(() => {
    getCentreSessionList();
    setSelectedOption(selectedSession?._id);
  }, [centre]);

  const getHelp = async (helpId) => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/help/${helpId}`;
    const response = await fetchData(url, "GET");
    setNewModalContent([
      <Help helpTitle={response.help_title} helpText={response.help_text} />,
      true,
    ]);
    setShowNewModal(true);
  };

  const sessions = sessionList?.map((item) => {
    return (
      <tr key={item._id}>
        <td className="session-radio-cell">
          <input
            type="radio"
            name="session"
            id={item._id}
            checked={selectedOption === item._id}
            disabled={dayjs(item.from_date).isBefore(
              dayjs(new Date()).startOf("date")
            )}
            className="session-radio"
            value={item._id}
            onChange={() => handleSessionChange(item._id)}
          />
        </td>
        <td>
          <label htmlFor={item._id}>{item.session_name}</label>
        </td>
        <td
          className={
            dayjs(item.from_date).isBefore(dayjs(new Date()).startOf("date"))
              ? "past-date"
              : undefined
          }
        >
          {dayjs(item.from_date).format("DD/MM/YYYY")} (
          {dayjs(item.from_date).format("ddd")})
        </td>
        <td
          className={
            dayjs(item.to_date).isBefore(dayjs(new Date()).startOf("date"))
              ? "past-date"
              : undefined
          }
        >
          {dayjs(item.to_date).format("DD/MM/YYYY")} (
          {dayjs(item.to_date).format("ddd")})
        </td>
        <td className="schedule-table-centred-column">
          <span
            title="Delete date range"
            onClick={() => deleteSession(item._id)}
          >
            <i className="fa-light fa-square-xmark fa-lg"></i>
          </span>
        </td>
      </tr>
    );
  });

  return (
    <div className="calendar-div">
      <div className="h2-with-help-icon">
        <h2>Dates - {centre?.centre_code}</h2>
        <img
          src={info_icon}
          className="module-info-icon help-icon"
          alt="Information icon"
          onClick={() => getHelp(3)}
        />
      </div>
      <form className="schedule-form">
        <input
          ref={sessionNameRef}
          name="sessionName"
          id="sessionName"
          className="schedule-form-title"
          placeholder="Date range name ..."
          value={sessionName}
          onChange={(e) => setSessionName(e.target.value)}
          required
        />
        <DatePicker
          todayButton="Today"
          dateFormat="dd/MM/yyyy"
          calendarStartDay={1}
          className="schedule-form-date"
          minDate={new Date(today)}
          selected={fromDate}
          highlightDates={disabledDates}
          showWeekNumbers
          onChange={(fromDate) => setFromDate(fromDate)}
          monthsShown={2}
        />
        <DatePicker
          todayButton="Today"
          dateFormat="dd/MM/yyyy"
          calendarStartDay={1}
          className="schedule-form-date"
          minDate={new Date(today)}
          selected={toDate}
          highlightDates={disabledDates}
          showWeekNumbers
          onChange={(toDate) => setToDate(toDate)}
          monthsShown={2}
        />
        <button className="schedule-form-button" onClick={(e) => addSession(e)}>
          Add dates
        </button>
      </form>
      <table className="calendar-table">
        <thead>
          <tr>
            <th></th>
            <th>Date range name</th>
            <th>From</th>
            <th>To</th>
            <th>
              <span className="delete-icon">
                <i className="fa-light fa-square-xmark header-icon"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="session-radio-cell">
              <input
                type="radio"
                name="session"
                id="nosession"
                className="session-radio"
                checked={!selectedOption}
                value=""
                onChange={() => handleSessionChange()}
              />
            </td>
            <td>
              <label htmlFor="nosession">None</label>
            </td>
            <td>
              <i>Current date</i>
            </td>
            <td>
              <i>Current date</i>
            </td>
            <td></td>
          </tr>
          {sessions}
        </tbody>
      </table>
      <div className="modal-button-div">
        {selectedOption !== selectedSession?._id ? (
          <button
            className="schedule-form-button"
            onClick={confirmSessionChange}
          >
            Confirm
          </button>
        ) : (
          <button className="schedule-form-button" onClick={closeModal}>
            Close
          </button>
        )}
      </div>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}
