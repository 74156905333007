import React from "react";

const MODAL_STYLE = {
  position: "absolute",
  backgroundColor: "#FFF",
  margin: "30px",
  zIndex: "1000",
  minWidth: "15%",
  borderRadius: ".5em",
};
const MODAL_CONTENT_STYLE = {
  padding: "15px 35px",
};
const OVERLAY_STYLE = {
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0, .4)",
  zIndex: "1000",
  overflowY: "auto",
};

const NewModal = ({ content, open, setShowNewModal }) => {
  if (!open) return null;
  const [component, showCloseBtn] = content;
  return (
    <>
      <div
        style={OVERLAY_STYLE}
        onClick={() => (showCloseBtn ? setShowNewModal(false) : null)}
      >
        <div
          style={MODAL_STYLE}
          className="modal-div"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {showCloseBtn && (
            <div
              title="Close"
              className="modal-close-btn"
              onClick={() => setShowNewModal(false)}
            >
              <i className="fas fa-times"></i>
            </div>
          )}
          <div style={MODAL_CONTENT_STYLE} className="modal-content-div">
            {component}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewModal;
